var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c(
        "div",
        { staticClass: "projectTop" },
        [
          _c("p", { staticClass: "productTitle" }, [_vm._v(" 分组管理 ")]),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoHome } },
            [_vm._v(" 返回主页 ")]
          ),
        ],
        1
      ),
      _c("a-input-search", {
        staticStyle: { width: "400px" },
        attrs: { placeholder: "请输入分组名称", "enter-button": "" },
        on: { search: _vm.onSearch },
        model: {
          value: _vm.keyword,
          callback: function ($$v) {
            _vm.keyword = $$v
          },
          expression: "keyword",
        },
      }),
      _c(
        "p",
        { staticStyle: { margin: "20px 0", "text-align": "right" } },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.visible = true
                },
              },
            },
            [_vm._v(" 添加分组 ")]
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          locale: { emptyText: "暂无数据" },
          "table-layout": "auto",
          columns: _vm.columns,
          pagination: false,
          "row-key": "id",
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(record)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            width: 800,
            title: "添加分组",
            visible: _vm.visible,
            "ok-text": "确认",
            "cancel-text": "取消",
          },
          on: {
            ok: _vm.onSubmit,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "分组名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "分组描述", prop: "desc" } },
                [
                  _c("a-textarea", {
                    attrs: { placeholder: "请输入描述信息" },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }