<template>
    <div class="productWrap">
        <div class="projectTop">
            <p class="productTitle">
                分组管理
            </p>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <a-input-search v-model="keyword" placeholder="请输入分组名称" enter-button style="width: 400px" @search="onSearch" />
        <p style="margin: 20px 0; text-align: right">
            <a-button type="primary" @click="visible = true">
                添加分组
            </a-button>
        </p>
        <a-table
            :locale="{emptyText: '暂无数据'}"
            table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" style="margin-right: 20px" @click="gotoDetails(record)">详情</a>
                <a href="javascript:void(0);" @click="onDelete(record)">删除</a>
            </template>
        </a-table>
        <!-- 添加分组弹窗 -->
        <a-modal
            :width="800"
            title="添加分组"
            :visible="visible"
            ok-text="确认"
            cancel-text="取消"
            @ok="onSubmit"
            @cancel="visible = false">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item ref="name" label="分组名称" prop="name">
                    <a-input
                        v-model="form.name"
                        placeholder="最长不超过32位，中文，英文，数字及特殊字符，必须以中文或英文开头" />
                </a-form-model-item>
                <a-form-model-item label="分组描述" prop="desc">
                    <a-textarea
                        v-model="form.desc"
                        placeholder="请输入描述信息" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
const columns = [
    {
        title: '序号',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: '分组名称',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '分组ID',
        dataIndex: 'classId',
        key: 'classId'
    },
    {
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'createTime'
    },
    {
        title: '设备数量',
        dataIndex: 'number',
        key: 'number'
    },
    {
        title: '操作',
        width: '120px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
const data = [
    {
        id: 1,
        name: '西城天街组',
        classId: 'AAA',
        createTime: '2021-2-2',
        number: '10'
    },
    {
        id: 2,
        name: '西城天街组',
        classId: 'AAA',
        createTime: '2021-2-2',
        number: '10'
    }
]
export default {
    name: "ClassManagement",
    data() {
        return {
            // 搜索内容
            keyword: '',
            columns,
            data,
            // 添加分组弹窗是否显示
            visible: false,
            // 添加分组表单数据
            form: {
                name: '',
                desc: ''
            },
            // 添加分组表单验证规则
            rules: {
                name: [
                    { required: true, message: '请输入分组名称', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 搜索操作
        onSearch() {},
        // 查看详情操作
        gotoDetails() {
            this.$router.push('/equip-management/details')
        },
        // 删除操作
        onDelete() {
            this.$confirm({
                title: '删除分组',
                content: '是否确认删除分组（删除分组不会影响设备与项目绑定关系）',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.$message.success('删除分组成功！')
                }
            })
        },
        // 确认添加分组操作
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.visible = false
                    this.$message.success('添加成功！')
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.productWrap {
    margin: 30px 40px;
    .projectTop {
        display: flex;
        justify-content: space-between;
        .productTitle {
            font-size: 20px;
            font-weight: bold;
        }
    }
}
</style>
